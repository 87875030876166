import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from './Main.vue'
import Goods from './Goods.vue'
import Login from './Login.vue'
import Order from './Order.vue'
import OrderList from './OrderList.vue'


Vue.use(VueRouter)
const routes = [
    { path: '/', component: Main },
    { path: '/goods', component: Goods },
    { path: '/login', component: Login },
    { path: '/order', component: Order },
    { path: '/orderlist', component: OrderList }

]

const router = new VueRouter({
    routes
})


// 全局前置守卫
// router.beforeEach((to, from, next) => {
//     // 检查即将进入的路由是否是/login
//     if (to.path === '/login') {
//         // 如果是，直接放行
//         next();
//     } else {
//         // 否则，检查用户是否已登录
//         const isLoggedIn = localStorage.getItem('token'); // 假设这个变量用于判断用户是否已登录
        
//         // 如果用户未登录
//         if (!isLoggedIn) {
//             // 重定向到登录页面
//             next('/login');
//         } else {
//             // 如果用户已登录，则放行
//             next();
//         }
//     }
// });



export default router
