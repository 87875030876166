<template>
    <div class="box" v-loading="loading">
        <div class="left">
            <div v-for="(item, index) in list" :key="index" class="item">
                <img :src="url + item.image" alt="">
                <div style="margin-top: 10px;width: 100%;">
                    <div>{{ item.title }}</div>
                    <div class="flex">
                        <span class="price">$ {{ item.price.toFixed(2) }}</span>
                        <el-input-number @change="handleChange(item)" style="width: 100px;" size="mini"
                            v-model="item.num"></el-input-number>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <div style="font-weight: 550;margin-bottom: 20px;font-size: 18px;">Customer Information</div>
            <div style="display: flex;width:100%;justify-content: space-between;">
                <div style="width: 48%;">
                    <div style="margin-bottom: 4px;display: block;">
                        <span>Email</span>
                        <span class="require">*</span>
                    </div>
                    <el-input style="width: 100%;" disabled v-model="email"></el-input>
                </div>
                <div style="width: 48%;">
                    <div style="margin-bottom: 4px;display: block;">
                        <span>Phone Number</span>
                        <span class="require">*</span>
                    </div>
                    <el-input style="width: 100%;" v-model="phoneNumber"></el-input>
                </div>
            </div>
            <div style="height: 20px;"></div>
            <div style="display: flex;width:100%;justify-content: space-between;">
                <div style="width: 48%;">
                    <div style="margin-bottom: 4px;display: block;">
                        <span>First Name</span>
                        <span class="require">*</span>
                    </div>
                    <el-input style="width: 100%;" v-model="firstName"></el-input>
                </div>
                <div style="width: 48%;">
                    <div style="margin-bottom: 4px;display: block;">
                        <span>Last Name</span>
                        <span class="require">*</span>
                    </div>
                    <el-input style="width: 100%;" v-model="lastName"></el-input>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 20px;align-items: center;">
                <div>total: <span style="color: red;">$ {{ total }}</span></div>
                <el-button @click="createOrder" style="width: 48%;" type="primary" plain>Create Order</el-button>
            </div>
        </div>
        <div class="left media">
            <div v-for="(item, index) in list" :key="index" class="item">
                <img :src="url + item.image" alt="">
                <div style="margin-top: 10px;width: 100%;">
                    <div>{{ item.title }}</div>
                    <div class="flex">
                        <span class="price">$ {{ item.price.toFixed(2) }}</span>
                        <el-input-number @change="handleChange(item)" style="width: 100px;" size="mini"
                            v-model="item.num"></el-input-number>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js'
export default {
    name: 'Order',
    data() {
        return {
            list: [],
            userInfo: {},
            phoneNumber: "",
            firstName: '',
            lastName: '',
            loading: false,
            email: ''
        }
    },
    mounted() {
        this.url = this.$domain
        this.email = localStorage.getItem('email')
        this.cartList()
    },
    computed: {
        total: function () {
            let total = 0
            for (let i = 0; i < this.list.length; i++) {
                total += this.list[i].price * this.list[i].num
            }
            return total.toFixed(2)
        }
    },
    created() {
        // 监听来自ChildComponent的事件  
        EventBus.$on('userInfo', (data) => {
            this.userInfo = data
        });
    },
    beforeDestroy() {
        // 组件销毁前，移除事件监听器，避免内存泄漏  
        EventBus.$off('userInfo');
    },
    methods: {
        cartList() {
            this.loading = true
            this.$axios('get', '/goods/index/cartList', {
                str: localStorage.getItem('cart')
            }).then(res => {
                this.list = res.data.data
                this.loading = false
            })
        },
        placeOrder() {
            let token = localStorage.getItem('token')
            if (!token) {
                this.$router.push({
                    path: '/login'
                })
                return false
            }
            const routeUrl = this.$router.resolve({
                path: '/order'
            })
            window.open(routeUrl.href, '_blank')
        },
        handleChange(e) {
            this.loading = true
            let cart = localStorage.getItem('cart')
            cart = JSON.parse(cart)
            let itemNum = 0;
            for (let i = 0; i < cart.length; i++) {
                if (cart[i] == e.id) {
                    itemNum++
                }
            }
            if (e.num < itemNum) {        //删除
                let difference = itemNum - e.num
                for (let t = 0; t < difference; t++) {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i] === e.id) {
                            cart.splice(i, 1)
                            break;
                        }
                    }
                }

            } else if (e.num > itemNum) {        //添加
                cart.push(e.id)
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            EventBus.$emit('refreshBadge', true);
            this.loading = false
        },
        createOrder() {
            let cart = localStorage.getItem('cart')
            if (!cart) {
                this.$notify.error('Please add Goods')
                return false
            }
            if (!this.email) {
                this.$notify.error('Please Refresh the page')
                return false
            }
            if (!this.phoneNumber) {
                this.$notify.error('Please Enter a Phone Number')
                return false
            }
            if (!this.firstName || !this.lastName) {
                this.$notify.error('Please Enter First Name and Last Name')
                return false
            }
            this.$axios('post', '/order/index/createOrder', {
                token: localStorage.getItem('token'),
                phoneNumber: this.phoneNumber,
                firstName: this.firstName,
                lastName: this.lastName,
                cartList: cart
            }).then(res => {
                if (res.data.status !== 'success') {
                    this.$notify.error(res.data.msg)
                    return false
                }
                this.$notify.success(res.data.msg)
                this.$router.replace('/orderlist');
                localStorage.removeItem('cart')
                EventBus.$emit('refreshBadge', true);
            })
        }
    }
}
</script>

<style scoped>
#app{
    width: 100vw;
}
.item {
    margin: 20px;
    display: flex;
}

.item>img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.flex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.left {
    width: 40%;
    overflow: scroll;
    height: 80vh;
}

.right {
    padding: 20px;
    width: 60%;
}


@media (max-width: 600px) {
    .left {
        width: 100%;
        overflow: visible;
        height: auto;
        display: none !important;
    }
    .media{
        display: block !important;
    }

    .right {
        width: 96%;
        margin-left: 2%;
        padding: 0;

    }
}

@media (min-width: 600px) {
    .box {
        display: flex;
        margin-top: 2rem;
    }
    .media{
        display: none !important;
    }
}


.bottom {
    width: 100%;
    position: absolute;
    bottom: 60px;
}

.placeOrder {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;

}

.totalBar {
    position: fixed;
    bottom: 50px;
}

.require {
    color: red;
    margin-left: 2px;
}
</style>