<template>
    <div id="app">
        <template v-if="path !== '/Login' && path !== '/login'">
            <Header class="Header" :path="path" :orderNum="orderNum" :userInfo="userInfo" @showDrawer="show"
                :badge="badge"></Header>
            <div class="media"></div>
        </template>
        <routerView />

        <div v-if="!(routerList.includes(path))" @click="showDrawer = true" class="cartBall">
            <el-badge :hidden="!badge" :value="badge" class="item">
                <i class="el-icon-shopping-cart-2"></i>
            </el-badge>
        </div>

        <Footer class="Footer"></Footer>

        <el-drawer :size="size" :direction="direction" v-if="!(routerList.includes(path))"
            :title="`Your shopping cart (${badge})`" :visible.sync="showDrawer" @closeDrawer="closeDrawer">
            <CartDrawer :badge="badge"></CartDrawer>
        </el-drawer>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js'
import CartDrawer from './components/CartDrawer.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        CartDrawer,
        Header,
        Footer
    },
    data() {
        return {
            badge: 0,
            showDrawer: false,
            path: '/',
            userInfo: {},
            routerList: [
                '/Login', '/login', '/Order', '/order', '/OrderList', '/orderList', '/orderlist'
            ],
            orderNum: 0,
            direction: 'rtl',
            size: '50%'
        }
    },
    watch: {
        /* eslint-disable */
        '$route'(to, from) {
            this.path = to.path
            this.getUserInfo()
        },
        showDrawer() {
            if (window.innerWidth <= 768) {
                this.direction = 'btt'
                this.size = '60%'
            } else {
                this.direction = 'rtl'
                this.size = '50%'
            }
        }
    },
    mounted() {
        this.badgeNum()
        this.getUserInfo()
    },
    created() {
        // 监听来自ChildComponent的事件  
        this.path = this.$route.path
        console.log(this.$route.path)
        EventBus.$on('refreshBadge', (data) => {
            this.badgeNum()
        });
    },
    beforeDestroy() {
        // 组件销毁前，移除事件监听器，避免内存泄漏  
        EventBus.$off('refreshBadge');
    },
    methods: {
        badgeNum() {
            this.badge = (localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0) || 0
        },
        closeDrawer() {
            this.showDrawer = false
        },
        show() {
            this.showDrawer = true
        },
        getUserInfo() {
            this.$axios('get', '/index/index/userInfo', {
                token: localStorage.getItem('token')
            }).then(res => {
                if (res.data.data) {
                    this.orderNum = res.data.orderNum ? res.data.orderNum : 0
                    console.log(this.orderNum)
                    this.userInfo = res.data.data
                    localStorage.setItem('email', res.data.data.email)
                    EventBus.$emit('userInfo', this.userInfo)
                } else {
                    this.userInfo = {}
                }
            })
        }
    }
}
</script>

<style scoped>
.cartBall {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: fixed;
    bottom: 20%;
    right: 6%;
    background-color: #409EFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cartBall i {
    font-size: 30px;
    color: #fff;
}

.Header {
    position: fixed;
    width: 98vw;
    background-color: #fff;
    z-index: 999;
    top: 0;
}

.Footer {
    width: 98vw;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    margin-top: 1rem;
}

.media {
    height: 70px;
}


@media (max-width: 600px) {
    .media {
        height: 3rem;
    }
}

@media (min-width: 600px) {}
</style>