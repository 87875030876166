<template>
    <div>
        <div v-for="(item, index) in list" :key="index" class="item">
            <img :src="url + item.image" alt="">
            <div style="margin-top: 10px;width: 100%;">
                <div>{{ item.title }}</div>
                <div class="flex">
                    <span class="price">$ {{item.price.toFixed(2)}}</span>
                    <el-input-number @change="handleChange(item)" style="width: 100px;"size="mini" v-model="item.num"></el-input-number>
                </div>
            </div>
        </div>
        <div style="height: 40px;"></div>
        <div v-loading="loading" class="bottom">
            <el-button v-if="badge" @click="placeOrder" class="placeOrder" type="primary" plain>Place Order (total: $ {{total}})</el-button>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/eventBus.js';
export default {
    name: 'CartDrawer',
    data() {
        return {
            show: false,
            list: [],
            url: '',
            allBadge:0,
            loading:false
        }
    },
    props: {
        showDrawer: {
            type: Boolean,
            default: false
        },
        badge:{
            type: Number,
            default: 0
        }
    },
    watch: {
        showDrawer() {
            this.show = this.showDrawer
        },
        badge(){
            this.allBadge = this.badge
            this.cartList()
        }
    },
    computed: {
        total: function() {
            let total = 0
            for (let i = 0; i < this.list.length; i++) {
                total += this.list[i].price * this.list[i].num
            }
            return total.toFixed(2)
        }
    },

    mounted() {
        this.url = this.$domain
        this.cartList()
    },
    methods: {
        closeDrawer() {
            this.$emit('closeDrawer', true)
        },
        cartList() {
            this.loading = true
            this.$axios('get', '/goods/index/cartList', {
                str: localStorage.getItem('cart')
            }).then(res => {
                this.list = res.data.data
                this.loading = false
            })
        },
        placeOrder(){
            let token = localStorage.getItem('token')
            if(!token){
                this.$router.push({
                    path: '/login'
                })
                return false
            }
            const routeUrl = this.$router.resolve({
                path: '/order'
            })
            window.open(routeUrl.href, '_blank')
        },
        handleChange(e){
            this.loading = true
            let cart = localStorage.getItem('cart')
            cart = JSON.parse(cart)
            let itemNum = 0;
            for(let i = 0; i < cart.length; i++){
                if(cart[i] == e.id){
                    itemNum++
                }
            }
            if(e.num < itemNum){        //删除
                let difference = itemNum - e.num
                for(let t=0;t<difference;t++){
                    for(let i=0;i<cart.length;i++){
                        if(cart[i] === e.id){
                            cart.splice(i,1)
                            break;
                        }
                    }
                }

            }else if(e.num > itemNum){        //添加
                cart.push(e.id)
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            EventBus.$emit('refreshBadge', true);
            this.loading = false
        }
    }
}
</script>

<style scoped>
.item {
    margin: 20px;
    display: flex;
}

.item>img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.flex{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.bottom{
    width: 100%;
    position:absolute;
    bottom:60px;
    z-index: 99999;
}

@media (max-width: 600px) {
   .bottom{
        bottom: 2rem;
   }
    
}

@media (min-width: 600px) {
    .bottom{
        bottom: 60px;
   }
}

.placeOrder{
    width:80%;
    margin-left:10%;
    margin-top: 20px;
    z-index: 99999999999 !important;
}

</style>