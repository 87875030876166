<template>
    <div>
        <div class="main">
            <el-carousel ref="carousel" :initialIndex="nowCarousel" @change="carouselChange"
                indicator-position="outside" height="400px" class="images" :interval="5000" arrow="always">
                <el-carousel-item v-for="(item, index) in goodsInfo.images" :key="index">
                    <el-image class="imageWidth" :src="url + item">
                        <div slot="error" class="image-slot"></div>
                    </el-image>
                </el-carousel-item>
            </el-carousel>
            <div class="imagesBox">
                <div @click="clickItemChange(index)" v-for="(item, index) in goodsInfo.images">
                    <img :class="index === nowCarousel ? 'imageItem selectedImage' : 'imageItem'" :src="url + item"
                        alt="">
                </div>
            </div>
            <div>
                <div class="title">{{ goodsInfo.title }}</div>
                <!-- 库存数量在后台设置，核销后减少库存 -->
                <div class="inStock">In Stock</div>
                <div style="margin: 10px 0;">
                </div>
                <!-- 品牌第一个字大写 -->
                <div v-if="goodsInfo.name">Brand:{{ (goodsInfo.name.slice(0, 1)).toUpperCase() + goodsInfo.name.slice(1)
                    }}</div>
                <div>SKU:{{ goodsInfo.sku }}</div>
                <div class="pc">
                    <div class="price">${{ Number((goodsInfo.price)).toFixed(2) }}</div>
                    <div style="display: inline;">
                        <el-input-number style="margin-top: 20px;" v-model="num" :min="1"></el-input-number>
                    </div>
                </div>

                <div class="media">
                    <div class="price">${{ Number((goodsInfo.price)).toFixed(2) }}</div>
                    <div style="display: inline;">
                        <el-input-number v-model="num" :min="1"></el-input-number>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <el-button class="btn" @click="addtoCart(goodsInfo.id)" plain style="width: 300px;" type="primary">Add to
                        Cart</el-button>
                </div>
            </div>
        </div>

        <div class="description">Description</div>
        <div class="goodsName">{{ goodsInfo.title }}</div>
        <div class="content">{{ goodsInfo.content }}</div>


        <el-drawer :title="`Your shopping cart (${badge})`" :visible.sync="showDrawer" @closeDrawer="closeDrawer">
            <CartDrawer></CartDrawer>
        </el-drawer>

    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js';
import CartDrawer from './components/CartDrawer.vue'
export default {
    name: 'Main',
    data() {
        return {
            goodsInfo: {
                title: 'Title',
                image: './assets/p1.png',
                price: '99.99',
                description: 'This is a good product'
            },
            num: 1,
            value: 4,
            url: '',
            nowCarousel: 0,
            showDrawer: false,
            badge: 0
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.init()
        this.url = this.$domain
    },
    methods: {
        init() {
            this.$axios('get', '/goods/index/goodsInfo', {
                id: this.id
            }).then(res => {
                if (res.data.data.images) {
                    res.data.data.images = JSON.parse(res.data.data.images)
                }
                this.goodsInfo = res.data.data
            })
        },
        carouselChange(e) {
            this.nowCarousel = e
        },
        clickItemChange(e) {
            this.$refs.carousel.setActiveItem(e)
        },
        closeDrawer() {
            this.showDrawer = false
        },
        addtoCart(id) {
            let cart = JSON.parse(localStorage.getItem('cart')) || []
            for (let i = 0; i < this.num; i++) {
                cart.push(id)
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            EventBus.$emit('refreshBadge', true);
        }
    }
}
</script>

<style scoped>
.images {
    width: 40%;
    border: 1px solid #F2F3F5;
    padding: 20px;
    margin-right: 20px;
}

.flex {
    display: flex;
    margin: 20px;

}

.title {
    font-size: 22px;
    font-weight: 550;
}

.price {
    font-size: 20px;
    font-weight: 550;
    margin-top: 10px;
    color: #c10200;
}

.main {
    display: flex;
}

.el-image {
    display: block;
    margin: auto;
}

.inStock {
    font-size: 16px;
    font-weight: 500;
    color: #208920;
}

.imageItem {
    width: 100px;
    height: 100px;
    margin: 10px;
    border: 2px solid #F2F3F5;
    cursor: pointer;
}

.imageWidth {
    width: 400px;
}




@media (max-width: 1000px) {
    .imagesBox {
        display: none;
    }

    .images {
        width: 88vw;
        margin: auto;
    }

    .main {
        display: block;
    }

    .title {
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    .imageWidth {
        width: 300px;
    }

    .btn{
        display: block;
        margin: auto;
    }

}

@media (max-width: 600px) {
    .imagesBox {
        display: none;
    }

    .images {
        width: 88vw;
        margin: auto;
    }

    .main {
        display: block;
    }

    .title {
        font-size: 1.1rem !important;
        margin-top: 1rem;
    }

    .media {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .price {
        font-size: 20px;
        font-weight: 550;
        margin-top: 0;
        color: #c10200;
    }

    .pc{
        display: none;
    }

}


@media (min-width: 600px) {
    .media {
        align-items: center;
        justify-content: space-between;
        display: none !important;
    }
}


.imagesBox {
    margin-right: 20px;
}

.selectedImage {
    border: 2px solid #409EFF;
}

.description {
    font-size: 20px;
    font-weight: 600;
    margin: 20px;
    margin-top: 50px;
    margin-bottom: 0;
}

.goodsName {
    margin-left: 20px;
    font-weight: 550;
    margin-top: 6px;
    font-size: 16px;
}

.content {
    padding: 20px;
    padding-top: 2px;

}
</style>