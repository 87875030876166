<template>
    <div>
        <div style="margin: 20px;font-size: 20px;font-weight: 550;">Orders</div>
        <el-divider></el-divider>
        <div v-loading="loading" v-for="(item, index) in list" :key="index">
            <div style="margin: 20px;margin-bottom: 0;">
                <div>
                    <span>Order Status:</span>
                    <template>
                        <span style="color: #F9AE3D;" v-if="item.status === 0">Untreated</span>
                        <span style="color: #2979FF;" v-if="item.status === 1">Completed</span>
                        <span style="color: red;" v-else-if="item.status === 2">Cancelled</span>
                    </template>
                </div>
                <div>
                    <span>Order Number:</span>
                    <span>{{ item.orderNumber }}</span>
                </div>
                <div class="media">
                    <div>
                        <span>Email:</span>
                        <span>{{ email }}</span>
                    </div>
                    <div>
                        <span>Phone Number:</span>
                        <span>{{ item.phoneNumber }}</span>
                    </div>
                    <div>
                        <span>First Name:</span>
                        <span>{{ item.firstName }}</span>
                    </div>
                    <div>
                        <span>Last Name:</span>
                        <span>{{ item.lastName }}</span>
                    </div>
                </div>
                <div>
                    <span>Create Time:</span>
                    <span>{{ formatTimestamp(item.time) }}</span>
                </div>
            </div>
            <div style="display: flex;">
                <div class="left">
                    <div v-for="(item2, index2) in item.data" :key="index2" class="item">
                        <img :src="url + item2.image" alt="">
                        <div style="margin-top: 10px;width: 100%;">
                            <div>{{ item2.title }}</div>
                            <div class="flex">
                                <span class="price">$ {{ item2.price.toFixed(2) }}</span>
                                <el-input-number disabled @change="handleChange(item2)" style="width: 100px;"
                                    size="mini" v-model="item2.num"></el-input-number>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right" style="padding: 20px;width: 60%;">
                    <div style="display: flex;width:100%;justify-content: space-between;">
                        <div style="width: 40%;">
                            <div style="margin-bottom: 4px;display: block;">
                                <span>Email</span>
                                <span class="require">*</span>
                            </div>
                            <el-input style="width: 100%;" disabled v-model="email"></el-input>
                        </div>
                        <div style="width: 40%;">
                            <div style="margin-bottom: 4px;display: block;">
                                <span>Phone Number</span>
                                <span class="require">*</span>
                            </div>
                            <el-input disabled style="width: 100%;" v-model="item.phoneNumber"></el-input>
                        </div>
                    </div>
                    <div style="height: 20px;"></div>
                    <div style="display: flex;width:100%;justify-content: space-between;">
                        <div style="width: 40%;">
                            <div style="margin-bottom: 4px;display: block;">
                                <span>First Name</span>
                                <span class="require">*</span>
                            </div>
                            <el-input disabled style="width: 100%;" v-model="item.firstName"></el-input>
                        </div>
                        <div style="width: 40%;">
                            <div style="margin-bottom: 4px;display: block;">
                                <span>Last Name</span>
                                <span class="require">*</span>
                            </div>
                            <el-input disabled style="width: 100%;" v-model="item.lastName"></el-input>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px;align-items: center;">
                        <div>total:<span style="color: red;">$ {{ item.total }}</span></div>
                        <el-button v-if="item.status == 0" @click="deleteOrder(item.id)" style="width: 40%;"
                            type="danger" plain>Cancel
                            Order</el-button>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js'
export default {
    name: 'Order',
    data() {
        return {
            list: [],
            userInfo: {},
            phoneNumber: "",
            firstName: '',
            lastName: '',
            loading: false,
            email: '',
            meta:false
        }
    },
    mounted() {
        this.url = this.$domain
        this.email = localStorage.getItem('email')
        this.orderList()
    },
    computed: {
        total: function () {
            let total = 0
            for (let i = 0; i < this.list.length; i++) {
                total += this.list[i].price * this.list[i].num
            }
            return total.toFixed(2)
        }
    },
    watch:{
        showDrawer() {
                this.meta = window.innerWidth <= 768
        }
    },
    created() {
        // 监听来自ChildComponent的事件
        EventBus.$on('userInfo', (data) => {
            this.userInfo = data
        });
    },
    beforeDestroy() {
        // 组件销毁前，移除事件监听器，避免内存泄漏  
        EventBus.$off('userInfo');
    },
    methods: {
        orderList() {
            this.loading = true
            this.$axios('get', '/order/index/orderList', {
                token: localStorage.getItem('token')
            }).then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    for (let j = 0; j < res.data.data[i].data.length; j++) {
                        res.data.data[i].total = Number(res.data.data[i].total ? res.data.data[i].total : 0) + Number((res.data.data[i].data[j].price * res.data.data[i].data[j].num).toFixed(2));
                        res.data.data[i].total = res.data.data[i].total.toFixed(2)
                    }
                }
                this.list = res.data.data
                this.loading = false
            })
        },
        handleChange(e) {
            this.loading = true
            let cart = localStorage.getItem('cart')
            cart = JSON.parse(cart)
            let itemNum = 0;
            for (let i = 0; i < cart.length; i++) {
                if (cart[i] == e.id) {
                    itemNum++
                }
            }
            if (e.num < itemNum) {        //删除
                let difference = itemNum - e.num
                for (let t = 0; t < difference; t++) {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i] === e.id) {
                            cart.splice(i, 1)
                            break;
                        }
                    }
                }

            } else if (e.num > itemNum) {        //添加
                cart.push(e.id)
            }
            localStorage.setItem('cart', JSON.stringify(cart))
            EventBus.$emit('refreshBadge', true);
            this.loading = false
        },
        deleteOrder(id) {
            this.$confirm('Confirm to Cancel order?', 'Cancel Order', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Off',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                });
                this.$axios('post', '/order/index/delete', {
                    id,
                    token: localStorage.getItem('token')
                }).then(res => {
                    if (res.data.status == 'success') {
                        this.$notify.success(res.data.msg)
                        this.orderList()
                        loading.close();
                    } else {
                        this.$notify.error(res.data.msg)
                    }
                })
            }).catch(() => { })
        },
        formatTimestamp(timestamp) {
            // 将秒级别的时间戳转换为毫秒级别的时间戳
            const milliseconds = timestamp * 1000;
            // 创建一个新的 Date 对象
            const date = new Date(milliseconds);
            // 获取各个时间部分
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            // 格式化成 "YYYY-MM-DD HH:mm:ss"
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

    }
}
</script>

<style scoped>
.item {
    margin: 20px;
    display: flex;
}

.item>img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.left{
    width: 40%;
    overflow: scroll;
    height: 280px;
}

@media (max-width: 600px) {
    .media{
        display: block !important;
    }
    .right{
        display: none !important;
    }
    .left{
        width: 100%!important;
        overflow: scroll;
        height: 19rem;
    }
}

@media (min-width: 600px) {
    .media{
        display: none !important;
    }
    .right{
        display: block !important;
    }
}

.flex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
}

.bottom {
    width: 100%;
    position: absolute;
    bottom: 60px;
}

.placeOrder {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;

}

.totalBar {
    position: fixed;
    bottom: 50px;
}

.require {
    color: red;
    margin-left: 2px;
}
</style>