<template>
    <div class="">
        <div class="flex">
            <img class="logo" src="../assets/logo.png" alt="">
            <div class="Copyright">© Copyright 2022- 2024 Markham Tools. All Rights Reserved.</div>
            <div style="text-align: right;">
                <div>Tel: <a href="tel:+16476266868">+1 647 626 6868</a> </div>
                <div>Email:<a href="mailto:markhamtools@gmail.com">markhamtools@gmail.com</a></div>
                <div>Address:<a href="https://mapq.st/4fxTHyC">450 Alden Rd, Markham,<br> ON L3R 3L4</a></div>
            </div>
        </div>
        <div class="mediaCopyright">© Copyright 2022- 2024 Markham Tools. All Rights Reserved.</div>
    </div>

</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            search: ''
        }
    },
    props: {
        badge: Number,
        userInfo: Object,
        orderNum: Number,
        path: String
    },
    methods: {
        showDrawer() {
            this.$emit('showDrawer', true);
        },
        login() {
            if (!this.userInfo.email) {
                this.$router.push('login');
            }
        },
        Logout() {
            this.$confirm('logout?', '', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                localStorage.removeItem('token')
                location.reload(true);
            }).catch(() => { })
        },
        desensitizeEmail(email) {
            let [username, domain] = email.split('@');
            let len = username.length;
            let start = Math.floor(len / 3);
            let end = len - start;
            let hiddenPart = '*'.repeat(end - start);
            let desensitizedUsername = username.substring(0, start) + hiddenPart + username.substring(end);

            return desensitizedUsername + '@' + domain;
        },
        goToOrderList() {
            this.$router.push('orderlist');
        },
        goToHome() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        }
    }
}
</script>

<style scoped>
.header_btn {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 10px;
}

.header_btn:hover {
    background-color: #efefef;
}

.flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}


.logo {
    width: 180px;
    margin-top: 4px;
}

i {
    margin-right: 4px;
}

.Copyright {
    margin-bottom: 18px;
}

@media (max-width: 600px) {
    .Copyright {
        display: none;
    }

    .mediaCopyright {
        display: block;
        font-size: .7rem;
    }

    .logo {
        width: 120px;
    }
    .flex{
        font-size: .8rem;
        padding: 0.6rem;
        padding-bottom: 0;
    }
}

@media (min-width: 600px) {
    .Copyright {
        display: block;
    }

    .mediaCopyright {
        display: none;
    }
}

.mediaCopyright {
    text-align: center;
    margin-top: 1rem;
}
</style>