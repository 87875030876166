<template>
    <div>
        <div class="Category_title">Select by Brand</div>
        <div class="flex">
            <div @click="changeTab(0)" :class="nowTab === 0 ? 'category_item nowTab' : 'category_item'">
                <img :src="url + '/static/brand/all.png'" alt="">
                <span style="text-align: center;display: block;font-size: 0.8rem;">All</span>
            </div>
            <div v-for="(item, index) in brandList" :key="index" @click="changeTab(index + 1, item.id)"
                :class="nowTab === index + 1 ? 'category_item nowTab' : 'category_item'">
                <img :src="url + item.image" alt="">
                <span style="text-align: center;display: block;font-size: 0.8rem;">{{(item.name.slice(0,1)).toUpperCase() + item.name.slice(1)}}</span>
            </div>
        </div>
        <div style="height: 1.8rem;"></div>
    </div>
</template>

<script>
export default {
    name: 'Category',
    data() {
        return {
            nowTab: 0,
            url: ''
        }
    },
    props: {
        brandList: Array
    },
    mounted() {
        this.url = this.$domain
    },
    methods: {
        changeTab(index, id) {
            this.nowTab = index
            this.$emit('brandId', id)
        }
    }
}
</script>

<style scoped>
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.Category_title {
    font-weight: 550;
    margin-bottom: 20px;
}


.flex {
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.category_item:hover {
    animation: identifier .8s forwards;
}






@keyframes identifier {
    0% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}


.nowTab {
    opacity: 1 !important;
}



.m1 {
    background: url("../assets/m1.png") no-repeat;
}

.m2 {
    background: url("../assets/m2.png") no-repeat;
}

.b1 {
    background: url("../assets/b1.png") no-repeat;
}

.name {
    background-color: rgba(34, 34, 34, .90);
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    width: 100%;
}


.category_item {
    margin-right: 1.5rem;
    cursor: pointer;
    opacity: 0.7;
}

.category_item>img {
    width: 22vw;
    border-radius: 10px;
}



@media (max-width: 600px) {
    .category_item {
        margin-right: .48rem;
        cursor: pointer;
        opacity: 0.7;
    }

    .category_item>img {
        border-radius: .3rem;
    }
    
}

@media (min-width: 600px) {
    .category_item {
        margin-right: 1.5rem;
        cursor: pointer;
        opacity: 0.7;
    }
    .category_item>span {
        display: none !important;
    }
}
</style>