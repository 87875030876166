<template>
    <div id="app">
        <div class="login-container">
            <div v-loading="loading" class="login-box">
                <div style="font-size: 1.2rem;margin-bottom: 10px;font-weight: 550;">Please Log in With Your Email</div>
                <div class="tips">If there is no account, an account will be created automatically</div>
                <div style="margin-top: 10px;">Email:</div>
                <div class="input-group" style="margin-top: 8px;">
                    <el-input :disabled="sended" type="email" v-model="email" placeholder="Email"></el-input>
                </div>
                <div class="input-group">
                    <el-input v-if="sended" type="tel" v-model="code" placeholder="Code">
                        <div slot="suffix" style="display: flex;align-items: center;height: 100%;">
                            <span v-if="seconds" type="text">Regain in {{ seconds }} seconds</span>
                            <el-button @click="next" v-else type="text">Reacquire</el-button>
                        </div>
                    </el-input>
                </div>
                <div style="display: flex;justify-content: right;">
                    <el-button v-if="!sended" @click="next" type="primary" style="width: 40%;">Next</el-button>
                    <el-button v-else @click="login" type="primary" style="width: 40%;">Login</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            sended: false,
            code: '',
            seconds: 0,
            loading: false
        };
    },
    methods: {
        next() {
            if (!this.email) {
                this.$notify({
                    title: 'warning',
                    message: 'please enter your email',
                    type: 'warning'
                });
                return false
            }
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(this.email)) {
                this.$notify({
                    title: 'warning',
                    message: 'email format error',
                    type: 'warning'
                });
                return false
            }
            this.seconds = 0;
            setTimeout(() => {
                this.seconds = 60;
            }, 600)
            this.sended = true
            for (let i = 60; i >= 0; i--) {
                setTimeout(() => {
                    this.seconds = i;
                }, (60 - i) * 1000);
            }
            this.loading = true
            this.$axios('get', '/index/index/sendEmail', {
                email: this.email
            }).then(res => {
                this.md5_code = res.data.md5_code
                this.loading = false
            })
        },
        login() {
            if (!this.code) {
                this.$notify({
                    title: 'warning',
                    message: 'please enter code',
                    type: 'warning'
                });
                return false
            }
            this.loading = true
            this.$axios('POST', '/index/index/login', {
                email: this.email,
                code: this.code,
                md5_code: this.md5_code
            }).then(res => {
                this.loading = false
                if (res.data.status === 'error') {
                    this.$notify({
                        title: 'error',
                        message: res.data.msg,
                        type: 'error'
                    });
                    return false
                } else if (res.data.status === 'success') {
                    localStorage.setItem('token', res.data.token)

                    this.$router.push('/');
                }
            })
        }
    },
};
</script>

<style scoped>
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f2f2f2;
}

#app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-box {
    width: 50vw;
    background-color: white;
    padding: 2rem;
    border-radius: .5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.input-group {
    margin-bottom: 1.2rem;
}

input {
    width: 90%;
    padding: .8rem;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 1rem;
}

input:focus {
    border-color: #4285F4;
    outline: none;
}

.login-button {
    width: 100%;
    padding: 14px;
    background-color: #4285F4;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
}

.login-button:hover {
    background-color: #357ae8;
}

.footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.footer a {
    color: #4285F4;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}


@media (max-width: 600px) {
    .login-box {
        width: 75vw;
        padding: 2rem 1rem;
    }

    .tips {
        font-size: 0.8rem;
    }
}

@media (min-width: 600px) {
    .login-box {
        width: 580px;
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* 火狐 */
input {
    -moz-appearance: textfield;
}
</style>