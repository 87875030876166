import Vue from 'vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from "axios";

Vue.config.productionTip = false
Vue.use(ElementUI);
let requestUrl = 'https://www.markhamtools.com'
// let requestUrl = 'http://43.143.245.24'


Vue.prototype.$domain = requestUrl
Vue.prototype.$axios = function (type, url, data) {
  // url = '/api'+url

  let token = localStorage.getItem('token');
  if (type === 'post' || type === 'POST') {
    return axios.post(url, { ...data, token }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
  } else if (type === 'get' || type === 'GET') {
    return axios.get(url, {
      params: { ...data, token }
    });
  }
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

