<template>
    <div class="main">
        <div class="title">Products</div>
        <div class="list">
            <div class="item" v-for="(item,index) in goodsList" :key="index">
                <img @click="toGoods(item.id)" class="described" :src="url+item.image" alt="">
                <div style="position: relative;height: 8rem;">
                    <a @click="toGoods(item.id)" class="described ellipsis">{{item.title}}</a>
                    <div class="price">$ {{ item.price.toFixed(2) }}</div>
                    <el-button class="addtoCart" @click="addtoCart(item.id)" plain type="primary"
                        icon="el-icon-plus">Add To Cart</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js';
export default {
    name: 'List',
    data() {
        return {
            url:''
        }
    },
    mounted(){
        this.url = this.$domain
    },
    props:{
        goodsList:Array
    },
    methods: {
        toGoods(id) {
            const routeUrl = this.$router.resolve({
                path: '/goods',
                query: { id }
            })
            window.open(routeUrl.href, '_blank')
        },
        addtoCart(id){
            let cart = JSON.parse(localStorage.getItem('cart')) || []
            cart.push(id)
            localStorage.setItem('cart', JSON.stringify(cart))
            EventBus.$emit('refreshBadge', true);
        }
    }
}
</script>

<style scoped>
.main {
    background-color: #222;
    padding: 1rem;
}

.title {
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    background-color: #fff;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 4px;

}

.item>img {
    width: 90%;
    height: 10rem;
    display: block;
    margin: auto;
    margin-bottom: 10px;
    object-fit: cover;
}

@media (max-width: 600px) {
    .item{
        width: 37.6%;
        font-size: 0.9rem;
    }
    .item>img {
        height: 6rem;
    }
    .addtoCart{
        font-size: 0.7rem;
    }
}

@media (min-width: 600px) {
    .item{
        width: 11rem;
    }
    .item>img {
        height: 10rem;
    }
    .addtoCart{
        font-size: 1rem;
    }
}

.price {
    font-family: myriad-pro-condensed, sans-serif !important;
    font-weight: 700;
    margin-top: 10px;
}

.described:hover {
    font-weight: 550;
    text-decoration: underline;
    cursor: pointer;
}



.addtoCart {
    width: 100%;
    position: absolute;
    bottom: 6px;

}
.ellipsis {
    display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
}
</style>