<template>
    <div class="flex">
        <div style="cursor: pointer;" @click="goToHome">
            <img class="logo" src="../assets/logo.png" alt="">
        </div>
        <!-- <el-input style="width: 30%;" placeholder="Search..." v-model="search">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input> -->
        <div class="flex ">
            <div @click="login" class="header_btn media">
                <i class="el-icon-user"></i>
                <span v-if="userInfo.email">{{ desensitizeEmail(userInfo.email) }}</span>
                <span v-else>Sign in</span>
            </div>
            <div v-if="userInfo.email" style="color: #E50426;" @click="Logout" class="header_btn">
                Logout
            </div>
            <template v-if="path != '/orderlist' && path != '/OrderList'">
                <el-badge :hidden="!orderNum" :value="orderNum" class="item">
                    <div @click="goToOrderList" style="margin-left: 20px;" class="header_btn">
                        <i class="el-icon-tickets"></i>
                        My Orders
                    </div>
                </el-badge>
                <el-badge :hidden="!badge" :value="badge" class="item media">
                    <div @click="showDrawer" style="margin-left: 20px;" class="header_btn">
                        <i class="el-icon-shopping-cart-2"></i>
                        Cart
                    </div>
                </el-badge>
                <div style="width: 10px;"></div>
            </template>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            search: ''
        }
    },
    props: {
        badge: Number,
        userInfo: Object,
        orderNum: Number,
        path: String
    },
    methods: {
        showDrawer() {
            this.$emit('showDrawer', true);
        },
        login() {
            if (!this.userInfo.email) {
                this.$router.push('login');
            }
        },
        Logout() {
            this.$confirm('logout?', '', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                localStorage.removeItem('token')
                location.reload(true);
            }).catch(() => { })
        },
        desensitizeEmail(email) {
            let [username, domain] = email.split('@');
            let len = username.length;
            let start = Math.floor(len / 3);
            let end = len - start;
            let hiddenPart = '*'.repeat(end - start);
            let desensitizedUsername = username.substring(0, start) + hiddenPart + username.substring(end);

            return desensitizedUsername + '@' + domain;
        },
        goToOrderList() {
            if (this.userInfo.email) {
                this.$router.push('orderlist');
            } else {
                this.$router.push('login');
            }
        },
        goToHome() {
            if (this.$route.path !== '/') {
                this.$router.push('/');
            }
        }
    }
}
</script>

<style scoped>
.header_btn {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 10px;
}

.header_btn:hover {
    background-color: #efefef;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 1000px) {
    .right {
        display: none;
    }

    .logo {
        width: 20vw !important;
        margin-top: 1rem !important;
    }

    .media{
        display: none !important;
    }
}
@media (max-width: 600px) {
    .right {
        display: none;
    }

    .logo {
        width: 30vw !important;
        margin-top: 1rem !important;
    }

    .media{
        display: none !important;
    }
}



@media (min-width: 600px) {
    .right {
        display: flex;
    }
}


.logo {
    width: 10rem;
    margin-top: 4px;
}

i {
    margin-right: 4px;
}
</style>