<template>
    <div>
        <div style="height: 20px;"></div>
        <Category @brandId="changeTab" :brandList="brandList"></Category>
        <List v-loading="loading" :goodsList="goodsList"></List>
    </div>
</template>

<script>
import Category from './components/Category.vue'
import List from './components/List.vue'

export default {
    name: 'Main',
    components: {
        Category,
        List,
    },
    data() {
        return {
            brandList: [],
            goodsList: [],
            brandId: 0,
            loading: false
        }
    },
    mounted() {
        this.lst()
    },
    methods: {
        lst() {
            this.loading = true
            this.$axios('get', '/goods/index/list', {
                brandId: this.brandId
            }).then(res => {
                this.brandList = res.data.brand
                this.goodsList = res.data.goods
                this.loading = false
            })
        },
        changeTab(brandId) {
            this.brandId = brandId
            this.lst()
        }
    }
}
</script>

<style scoped>

</style>